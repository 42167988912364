import React, {Component} from 'react';
import ProblemSolutionRow from './problem-solution-row';
import './style.scss';

class ProblemSolutionRows extends Component {
    render() {
        return (
            <section className="problem-solution-section">
                <div className="content-wrapper">
                    {this.props.data.map((item, i) => {
                        return (
                            ProblemSolutionRow(item, i)
                        )
                    })}
                </div>
            </section>
        )
    }
}

export default ProblemSolutionRows