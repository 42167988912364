import React, {Component} from 'react';
import {graphql} from "gatsby";
import Layout from '../components/layout';
import MidPageInfoBanner from '../components/mid-page-info-banner';
import ProblemSolutionTable from '../components/problem-solution-table';
import {IS_MOBILE} from '../common/consts';
import JoinNetwork from '../components/join-network';
import PromoFooter from "../components/promo-footer-banner";
import HeadlineAndList from '../components/headline-and-list';
import {mapResourcesList, videoSectionMapper, mapCoolInsights, mapStickyBanner, mapGartner} from '../common/utils';
import FAQ from '../components/faq';
//
// const questions = [{
//     question: 'What is SD-WAN?',
//     answer: 'SD-WAN is the future of networking'
// },{
//     question: 'Is there a better example of SD-WAN than Cato`s current architecture?',
//     answer: 'Extremely unlikely'
// }]

class SolutionsInnerTemplate extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isMobile: false
        }
    }

    componentDidMount() {
        if(IS_MOBILE) {
            this.setState({isMobile: true})
        }

    }

    mapProblemChallenge = (acf) => {
        const {problemSolutionRows} = acf;

        return problemSolutionRows.map((row, i) => ({
            headline: row.sectionHeadline || null,
            notCentered: row.leftAlignHeadline,
            problem: {
                bannerText: i === 0 && acf.problemSolutionMainProblemHeadline ? acf.problemSolutionMainProblemHeadline : undefined,
                headline: row.problemHeadline,
                content: row.problemText
            },
            solution: {
                bannerText: i === 0 && acf.problemSolutionMainSolutionHeadline ? acf.problemSolutionMainSolutionHeadline : undefined,
                headline: row.solutionHeadline,
                content: row.solutionText
            },

        }))

    };

    mapHeadingListItems = (acf) => {

        return acf.headingWithListItems.map(item => ({
            greenText: item.greenText,
            text: item.text
        }))
    };

    render(){
        const {data} = this.props;
        const {version} = this.props.pageContext;
        const {wpPage} = data;
        const {headerWithQuote, headingWithList, problemSolutionTableSolutionsInner, midPageInfoSection, videoSection, resourcesBannerRows, FAQSection, tryUsStrip} = wpPage;

        const headingAndList = headingWithList && headingWithList.headingWithListItems ? this.mapHeadingListItems(headingWithList) : null;

        const problemSolution = problemSolutionTableSolutionsInner && problemSolutionTableSolutionsInner.problemSolutionRows ? this.mapProblemChallenge(problemSolutionTableSolutionsInner) : null;

        const resourcesData = resourcesBannerRows.resourcesList ? mapResourcesList(resourcesBannerRows.resourcesList) : null;

        const youtube = videoSection && videoSection.videos ? videoSectionMapper(videoSection.videoSectionHeadline, videoSection.videos) : null;

        const coolInsights = mapCoolInsights(wpPage);

        const stickyBanner = mapStickyBanner(wpPage);

        const questions = FAQSection && FAQSection.faq ? FAQSection.faq.map(f => ({question: f.faqQuestion, answer: f.faqAnswer})) : null;

        const gartner = mapGartner(wpPage);
        const breadcrumbs = wpPage.parent ?
            [{title: wpPage.parent.pageSeoTitle, path: wpPage.parent.path},{title: wpPage.title, path: this.props.location.pathname}]
            :
            [{title: wpPage.title, path: this.props.location.pathname}]

        return (
            <Layout className="solutions-inner-page"
                    lang={this.props.pageContext.lang}
                    whiteMenu={true}
                    yoastData={wpPage.seo || null}
                    seoTitle={wpPage.pageSeoTitle.pageSeoTitle}
                    breadcrumbs={breadcrumbs}
                    title={wpPage.title}
                    wpid={wpPage.databaseId}
                    gartner={gartner}
                    seoPath={this.props.location.pathname}
                    translations={this.props.pageContext.translations}
                    {...{stickyBanner}}
                    {...{youtube}}
                    {...{coolInsights}}

            >
                <section className="banner-section" style={{paddingBottom: 0}}>
                    {headerWithQuote &&
                        <MidPageInfoBanner
                            sideQuote={headerWithQuote.headerQuoteCustomerQuote && headerWithQuote.headerQuoteIncludeQuote ? {
                                quote: headerWithQuote.headerQuoteCustomerQuote,
                                customerImg: headerWithQuote.headerQuoteCustomerImage
                                    ?
                                    headerWithQuote.headerQuoteCustomerImage
                                    : null,
                                customerName: headerWithQuote.headerQuoteCustomerName || "",
                                customerPosition: headerWithQuote.headerQuoteCustomerPosition || "",
                                logo: headerWithQuote.customerCompanyLogo ?
                                    headerWithQuote.customerCompanyLogo
                                    :
                                    null
                            } : null}
                            alignImage={false}
                            includeSideOnMobile={true}
                            heading={headerWithQuote.headerQuoteTitle || ""}
                            text={headerWithQuote.headerQuoteText || ""}
                            isTopSection={true}
                            withCarousel={false}
                            smallerGreenText={headerWithQuote.headerQuoteSmallerGreenText || ""}
                            smallerTopText={version === 2}
                            bannerImg={headerWithQuote.headerQuoteIncludeImage && headerWithQuote.headerQuoteImageImage ? headerWithQuote.headerQuoteImageImage : null}
                        />
                    }
                    {midPageInfoSection.middlePageTextImageRow && midPageInfoSection.middlePageTextImageRow.length &&
                    midPageInfoSection.middlePageTextImageRow.map((banner, i) =>
                        <MidPageInfoBanner
                            alignImage={false}
                            includeSideOnMobile={true}
                            bannerImg={banner.middlePageRowImage ? banner.middlePageRowImage : null}
                            isTopSection={false}
                            withCarousel={false}
                            longHeading={true}
                            heading={banner.middlePageRowHeadline}
                            text={banner.middlePageRowText}
                            key={`min-page-banner-${i}`}
                            imageFirst={Math.abs(i % 2) === 0}
                            offWhite={Math.abs(i % 2) === 0}
                            link={banner.link || null}
                            anchorID={banner.anchorId || null}
                        />)}

                </section>
                {version === 1 && problemSolution && <ProblemSolutionTable data={problemSolution} />}
                {version === 2 && headingAndList && <HeadlineAndList headline={headingWithList.headingWithList} list={headingAndList}/>}
                {questions  && <FAQ headline={FAQSection.faqHeadline || ""} questions={questions}/>}
                {resourcesData &&
                <div className={'ebook-section-wrapper'}>
                    <PromoFooter className={"show-small"} data={resourcesData}/>
                </div>}

                {/*{resourcesData && <ResourcesFooterBanner data={resourcesData} />}*/}
                <JoinNetwork
                    headline={tryUsStrip.tryUsHeadingText ? tryUsStrip.tryUsHeadingText : undefined}
                    buttonText={tryUsStrip.tryUsButtonText ? tryUsStrip.tryUsButtonText : undefined}
                />
            </Layout>
        )
    }
}

export default SolutionsInnerTemplate;

export const pageQuery = graphql`
    query solutionsInnerPageQuery($id: String!) {
        wpPage(id: { eq: $id }) {
            ...YoastData
            title
            content
            slug
            id
            databaseId
            date(formatString: "MMMM DD, YYYY")
            parent {
                ... on WpPage {
                    pageSeoTitle: pageSeoTitle {
                        pageSeoTitle
                    }
                    path: uri
                }
            }
            path: uri
            pageSeoTitle: pageSeoTitle {
                pageSeoTitle
            }
            headerWithQuote {
                headerQuoteTitle
                headerQuoteSmallerGreenText
                headerQuoteText
                headerQuoteCustomerQuote
                customerCompanyLogo {
                    altText
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 200) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                        }
                        extension
                        publicURL
                    }
                }
                headerQuoteCustomerImage {
                    altText
                    localFile {
                        childImageSharp {
                            fixed(width: 50) {
                               ...GatsbyImageSharpFixed_withWebp_noBase64
                            }
                        }
                        extension
                        publicURL
                    }
                }
                headerQuoteCustomerName
                headerQuoteCustomerPosition
                headerQuoteIncludeQuote
                headerQuoteIncludeImage
                headerQuoteImageImage {
                    altText
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 500) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                        extension
                        publicURL
                    }
                }
            }
            headingWithList {
                headingWithList
                headingWithListItems {
                    greenText
                    text
                }
            }
            problemSolutionTableSolutionsInner {
                problemSolutionMainProblemHeadline
                problemSolutionMainSolutionHeadline
                problemSolutionRows {
                    sectionHeadline
                    leftAlignHeadline
                    problemHeadline
                    problemText
                    solutionHeadline
                    solutionText
                }
            }
            midPageInfoSection {
                middlePageTextImageRow {
                    middlePageRowHeadline
                    middlePageRowText
                    middlePageRowImage {
                        altText
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 500) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                            extension
                            publicURL
                        }
                    }
                }
            }
            resourcesBannerRows {
                resourcesList {
                    ... on WpResource {
                        title
                        resourceFields {
                            resourceLongDescription
                            resourceSmallImage {
                                altText
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 400) {
                                            ...GatsbyImageSharpFluid_noBase64
                                        }
                                    }
                                    extension
                                    publicURL
                                }
                            }
                            resourceButtonText
                            resourceLink
                        }
                    }
                }
            }
            tryUsStrip {
                tryUsHeadingText
                tryUsButtonText
            }
            ...FAQSection
            ...StickyBanner
            ...VideoSection
            ...ResourcesTrio
            ...GartnerStrip
            
        }
        site {
            id
            siteMetadata {
                title
            }
        }
    }
`