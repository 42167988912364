import React from 'react';
import '../style.scss';

const ProblemSolutionRow = (props, i) => {

    return (
        <div className="problem-solution-row" key={`problem-solution-row-${i}`}>
            {props.problem.bannerText && props.solution.bannerText && <div className="banner-text">
                {props.problem.bannerText && <div className="problem">
                    <h2>{props.problem.bannerText}</h2>
                </div>}
                {props.solution.bannerText &&
                <div className="solution">
                    <h2>{props.solution.bannerText}</h2>
                </div>}
            </div>}
            {props.headline &&
            <h3 className={`headline`}>{props.headline}</h3>}
            <div className="problem-solution-wrapper">
                <div className="problem">
                    <h4>{props.problem.headline}</h4>
                    <div dangerouslySetInnerHTML={{__html: props.problem.content}} />
                </div>
                <div className="solution">
                    <h4>{props.solution.headline}</h4>
                    <div dangerouslySetInnerHTML={{__html: props.solution.content}} />
                </div>
            </div>
        </div>
    )
};

export default ProblemSolutionRow;