import React from 'react';
import './style.scss';

const HeadlineAndList = (props) => {

    const {headline, list} = props;

    // const genList = () => {
    //     return list.map((item, i) => {
    //         <li key={`list-item-${i}`}>
    //             {item.greenText && <h3>{item.greenText}</h3>}
    //             {item.text && <div>{item.text}</div>}
    //         </li>
    //     })
    // }

    return (
        <section className="headline-and-list-section">
            <div>
                <div className="headline-wrapper"><h1>{headline}</h1></div>
                <div className="list-wrapper">
                    <ul className={'hide-bullets-style'}>
                        {
                            list.map((item, i) => (
                                <li key={`list-item-${i}`}>
                                    {item.greenText && <h3>{item.greenText}</h3>}
                                    {item.text && <div dangerouslySetInnerHTML={{__html: item.text}} />}
                                </li>)
                            )
                        }
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default HeadlineAndList;